<template>
    <div>
        <b-card class="reports-list">
            <b-card-header class="card-header-main">
                <b-row class="reports-list-header btn-row">
                    <b-col>
                        <b-button variant="primary" @click="createDataStudioReport">
                            <i class="icon-plus mr-1"></i>Add a Report
                        </b-button>
                        <b-button variant="primary" @click="showCategoryModal = true">
                            <i class="icon-plus mr-1"></i>Add a Category
                        </b-button>
                    </b-col>
                </b-row>
            </b-card-header>
            <b-card-body>
                <b-row class="filter-reports-by-category-row">
                    <b-col md="8">
                        <b-form-group label="Filter by category" :label-cols="3" :horizontal="true">
                            <b-row>
                                <b-col md="10">
                                    <wit-select
                                        v-model="categoryFilter"
                                        placeholder="Select category"
                                        :options="dataStudioCategories"
                                        @input="setCategoryFilter"
                                        label="name"
                                    ></wit-select>
                                </b-col>
                            </b-row>
                        </b-form-group>
                    </b-col>
                </b-row>

                <wit-table
                    mode="server"
                    :fields="dataStudioColumns"
                    :request-function="requestFunction"
                    striped
                    paginated
                    hide-per-page-selector
                    ref="dataStudioReportsTable"
                    :custom-query-params="customQueryParams"
                    :default-per-page="10"
                >
                    <template v-slot:cell(category)="{item}">
                        {{ findReportCategoryName(item) }}
                    </template>
                    <template v-slot:cell(actions)="{item}">
                        <div class="button-row">
                            <OpenAction :openResource="openReport" :resource="item" />
                            <EditAction :editResource="showEditDataStudioReportModal" :resource="item" />
                            <RemoveAction :removeResource="showRemoveDataStudioReportModal" :resource="item" />
                        </div>
                    </template>
                </wit-table>
            </b-card-body>
        </b-card>
        <RemoveRecordModal
            v-model="showRemoveModal"
            :removeElement="removeDataStudioReport"
            :recordId="removeReportId"
            @close="recordId = null"
        />
        <DataStudioReportModal
            v-model="reportForm"
            :show="showReportForm"
            :mode="dataStudioReportModalMode"
            :categories="dataStudioCategories"
            @close="closeDataStudioModal"
        />
        <CategoryModal v-model="categoryForm" :show="showCategoryModal" @close="closeCategoryModal" />
        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import Loading from '@/components/loading.vue'
import Feedback from '@/components/Feedback.vue'
import RemoveRecordModal from '@/components/Modals/RemoveRecordModal.vue'
import ReportList from '@/components/ReportList.vue'
import ScheduleBackfill from '@/components/ScheduleBackfill.vue'
import WitTable from '@/components/WitTable.vue'

import CategoryModal from './CategoryModal.vue'
import DataStudioReportModal from './DataStudioReportModal'

import OpenAction from '@/components/Actions/OpenAction.vue'
import EditAction from '@/components/Actions/EditAction.vue'
import RemoveAction from '@/components/Actions/RemoveAction.vue'

import {mapGetters} from 'vuex'

import VueNotifications from 'vue-notifications'

export default {
    components: {
        CategoryModal,
        DataStudioReportModal,
        Loading,
        Feedback,
        RemoveRecordModal,
        OpenAction,
        EditAction,
        RemoveAction,
        WitTable,
    },
    mounted() {
        this.reloadDataStudioTableInterval = setInterval(this.refreshDataStudioTable, 5000)
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),
    },
    destroyed() {
        clearInterval(this.reloadDataStudioTableInterval)
    },
    data() {
        return {
            showCategoryModal: false,
            showReportForm: false,
            reportForm: {},
            categoryForm: {},
            dataStudioError: {},
            dataStudioApiUrl: `${process.env.VUE_APP_NODE_API_HOST}/report/dataStudio`,
            dataStudioColumns: ['name', 'category', 'actions'],
            showRemoveModal: false,
            removeReportId: null,
            categoryFilter: null,
            dataStudioCategories: [],
            dataStudioReportModalMode: 'create',
            customQueryParams: {},
        }
    },
    created() {
        this.$store.commit('loading/PROCESSING', `Preparing the table...`)
        this.axios
            .get(`${process.env.VUE_APP_NODE_API_HOST}/report/dataStudio/categories`, {
                params: {
                    projectId: this.activeProject.id,
                },
            })
            .then(response => {
                this.dataStudioCategories = response.data.data
                this.$store.commit('loading/PROCESSED')
            })
            .catch(exception => {
                this.$store.commit('loading/PROCESSED')
            })
    },
    methods: {
        async requestFunction(params) {
            const response = await this.axios.get(this.dataStudioApiUrl, {
                params: {
                    ...params,
                    projectId: this.$store.state.project.active.id,
                },
            })

            return {
                data: response.data.data,
                count: response.data.metadata.count,
            }
        },
        setCategoryFilter(category) {
            this.customQueryParams.categoryId = category ? category.id : null
            this.reloadDataStudioTable()
        },
        findReportCategoryName(report) {
            if (report.categoryRef) {
                const category = this.dataStudioCategories.find(el => el.id === report.categoryRef.id)
                return category ? category.name : 'Uncategorized'
            } else return 'Uncategorized'
        },
        refreshDataStudioTable() {
            if (!document.hidden && this.$refs.dataStudioReportsTable)
                this.$refs.dataStudioReportsTable.fetchData({silent: true})
        },
        reloadDataStudioTable() {
            if (!document.hidden && this.$refs.dataStudioReportsTable) this.$refs.dataStudioReportsTable.reload()
        },
        openReport(report) {
            window.open(report.url, '_blank')
        },
        createDataStudioReport() {
            this.reportForm = {}
            this.dataStudioReportModalMode = 'create'
            this.showReportForm = true
        },
        closeDataStudioModal() {
            this.showReportForm = false
            this.reloadDataStudioTable()
        },
        closeCategoryModal() {
            this.showCategoryModal = false
            this.axios
                .get(`${process.env.VUE_APP_NODE_API_HOST}/report/dataStudio/categories`, {
                    params: {
                        projectId: this.activeProject.id,
                    },
                })
                .then(response => {
                    this.dataStudioCategories = response.data.data
                })
                .catch(exception => {})
        },
        removeDataStudioReport() {
            this.$store.commit('loading/PROCESSING', `Removing...`)
            this.axios
                .delete(`${process.env.VUE_APP_NODE_API_HOST}/report/dataStudio/${this.removeReportId}`)
                .then(response => {
                    this.removeReportId = null
                    this.showRemoveModal = false
                    this.$refs.dataStudioReportsTable.refresh()
                    this.$store.commit('loading/PROCESSED')
                })
                .catch(exception => {
                    this.$store.commit('loading/PROCESSED')
                    console.error(exception)
                })
        },
        showRemoveDataStudioReportModal(report) {
            this.removeReportId = report.id
            this.showRemoveModal = true
        },
        showEditDataStudioReportModal(report) {
            this.dataStudioReportModalMode = 'edit'
            this.reportForm = {...report}
            this.showReportForm = true
        },
        validateDataStudio(ref) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)
            if (this.checkDataStudio) return !validateField.$invalid
            return validateField.$dirty ? !validateField.$invalid : null
        },
    },
}
</script>

<style <style lang="scss">
.close-create-report {
    font-weight: 600;
    position: absolute;
    right: 0.5%;
    top: 5%;
    cursor: pointer;
}

.btn-row {
    .btn {
        margin-right: 5px;
    }
}
</style>
