<template>
    <WitModal
        v-model="showModal"
        title="Setup the Report"
        variant="primary"
        @input="onChange"
        disableDBlock
        size="lg"
        customClass="category-modal"
    >
        <div role="tablist" class="tabbed-form">
            <b-card no-body class="mb-1 setup-category-card">
                <b-card-header header-tag="header" class="p-1 form-step-name" role="tab">
                    <img src="../../../assets/gcp-icons/step1.png" />
                    {{ tabs[0] }}
                </b-card-header>
                <b-collapse v-model="tabOpen[0]" visible role="tabpanel">
                    <b-card-body>
                        <b-form class="step-form">
                            <b-form-group label="Name" :label-cols="2" :horizontal="true">
                                <b-row>
                                    <b-col md="10">
                                        <b-form-input
                                            v-model="$v.reportForm.name.$model"
                                            type="text"
                                            placeholder="Enter report name"
                                        ></b-form-input>
                                        <Feedback
                                            :state="validateRef('reportForm.name', 0)"
                                            invalid="This field is required"
                                            valid="Name is valid"
                                        ></Feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>

                            <b-form-group
                                label="Data Studio Url"
                                :label-cols="2"
                                :horizontal="true"
                            >
                                <b-row>
                                    <b-col md="10">
                                        <b-form-input
                                            v-model="$v.reportForm.url.$model"
                                            type="text"
                                            placeholder="Url"
                                        ></b-form-input>
                                        <Feedback
                                            :state="validateRef('reportForm.url', 0)"
                                            invalid="This field is required"
                                            valid="Url is valid"
                                        ></Feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>

                            <b-form-group label="Category" :label-cols="2" :horizontal="true">
                                <b-row>
                                    <b-col md="10" class="report-category-col">
                                        <div class="report-category-col__flex">
                                            <b-form-checkbox
                                                style="margin-left: 5px"
                                                v-model="reportForm.categoryCheck"
                                            />
                                            <wit-select
                                                v-model="$v.reportForm.category.$model"
                                                placeholder="Select category"
                                                :options="categories"
                                                label="name"
                                                :disabled="!reportForm.categoryCheck"
                                            ></wit-select>
                                        </div>
                                        <Feedback
                                            v-if="reportForm.categoryCheck"
                                            :state="validateRef('reportForm.category', 0)"
                                            invalid="This field is required"
                                            valid="Category is valid"
                                        ></Feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>

                            <b-button
                                @click="() => (mode === 'create' ? createReport() : updateReport())"
                                variant="primary"
                            >
                                <i class="icon-check"></i>
                                {{ mode === 'create' ? 'Create' : 'Update' }}
                            </b-button>
                        </b-form>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>
    </WitModal>
</template>

<script>
import Feedback from '@/components/Feedback.vue'
import WitModal from '@/components/Modals/WitModal.vue'

import {mapGetters} from 'vuex'
import {validationMixin} from 'vuelidate'
import {required, requiredIf} from 'vuelidate/lib/validators'

const cloneDeep = require('lodash.clonedeep')

export default {
    components: {
        Feedback,
        WitModal,
    },
    props: {
        value: {
            type: Object | undefined,
            required: true,
        },
        show: {
            type: Boolean,
            required: true,
        },
        mode: {
            type: String,
            required: true,
        },
        categories: {
            type: Array,
            required: true,
        },
    },
    watch: {
        show() {
            this.$v.$reset()
            this.showModal = this.show
            this.onChange(this.show)
        },
        value() {
            this.$v.$reset()
            if (Object.keys(this.value).length > 0) {
                this.reportForm = cloneDeep({
                    name: this.value.name,
                    url: this.value.url,
                    category: this.value.categoryRef
                        ? this.categories.find(el => el.id === this.value.categoryRef.id)
                        : null,
                    categoryCheck: Boolean(this.value.categoryRef),
                })
            } else {
                this.reportForm = {
                    name: '',
                    url: '',
                    category: null,
                }
            }
        },
    },
    data() {
        return {
            reportForm: {
                name: '',
                url: '',
                category: null,
                categoryCheck: false,
            },
            tabs: ['Report Settings'],
            tabIndex: 0,
            checkPage: [],
            showModal: false,
        }
    },
    mixins: [validationMixin],
    validations: {
        reportForm: {
            name: {required},
            url: {
                required,
                url: string => {
                    const reg = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
                    return reg.test(string)
                },
            },
            category: {
                required: requiredIf(function() {
                    return this.reportForm.categoryCheck
                }),
            },
        },
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),
        tabOpen() {
            return this.tabs.map((tab, index) => (index === this.tabIndex ? true : false))
        },
    },
    created() {
        this.checkPage = this.tabs.map(el => false)
        if (Object.keys(this.value).length > 0) {
            this.reportForm = cloneDeep({name: this.value.name, url: this.value.url})
        }
    },
    methods: {
        toggleStep(step) {
            this.tabIndex = step
            this.$forceUpdate()
        },

        createReport() {
            this.checkPage[0] = true
            this.$forceUpdate()

            if (!this.$v.$invalid) {
                const {category, categoryCheck, ...form} = this.reportForm

                this.$store.commit('loading/PROCESSING', `Creating the Report...`)
                this.axios
                    .post(`${process.env.VUE_APP_NODE_API_HOST}/report/dataStudio`, {
                        ...form,
                        categoryId: categoryCheck ? category.id : null,
                        projectId: this.activeProject.id,
                    })
                    .then(async response => {
                        this.$store.commit('loading/PROCESSED')
                        this.close()
                    })
                    .catch(exception => {
                        this.$store.commit('loading/PROCESSED')
                        this.$errorHandler.report(exception, 'Could not create data studio report')
                    })
            }
        },

        updateReport() {
            this.checkPage[0] = true
            this.$forceUpdate()

            if (!this.$v.$invalid) {
                const {category, categoryCheck, ...form} = this.reportForm

                this.$store.commit('loading/PROCESSING', `Updating the Report...`)
                this.axios
                    .patch(`${process.env.VUE_APP_NODE_API_HOST}/report/dataStudio/${this.value.id}`, {
                        ...form,
                        categoryId: categoryCheck ? category.id : null,
                    })
                    .then(async response => {
                        this.$store.commit('loading/PROCESSED')
                        this.close()
                    })
                    .catch(exception => {
                        this.$store.commit('loading/PROCESSED')
                        this.$errorHandler.report(exception, 'Could not update data studio report')
                    })
            }
        },

        validateRef(ref, page) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)

            if (this.checkPage[page]) return !validateField.$invalid
            return validateField.$dirty ? !validateField.$invalid : null
        },

        onChange(show) {
            if (!show) {
                this.$emit('close')
            }
        },

        close() {
            this.showModal = false
            this.$emit('close')
        },
    },
}
</script>

<style lang="scss">
.report-category-col {
    .report-category-col__flex {
        display: flex;

        .custom-control {
            display: inline;
            margin-top: 10px;
        }
        .custom-control-label::before {
            border-radius: 4px;
            border: 1px solid #e8e8e8;
        }
        .wit-select {
            // width: 90%;
        }
    }
}
</style>
