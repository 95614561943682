<template>
    <span v-b-tooltip.hover title="Edit">
        <i class="edit-resource icon-note pointer-action" @click="editResource(resource)" variant="warning"></i>
    </span>
</template>

<script>
export default {
    props: {
        resource: {
            required: true,
            type: Object,
        },
        editResource: {
            required: true,
            type: Function,
        },
    },
}
</script>

<style>
.edit-resource {
    font-weight: 600;
}
</style>
