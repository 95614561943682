<template>
    <span v-b-tooltip.hover title="Open">
        <i class="open-resource fa fa-key pointer-action" @click="openResource(resource)" variant="warning"></i>
    </span>
</template>

<script>
export default {
    props: {
        resource: {
            required: true,
            type: Object,
        },
        openResource: {
            required: true,
            type: Function,
        },
    },
}
</script>

<style></style>
