<template>
    <WitModal
        v-model="showModal"
        title="Setup the Category"
        variant="primary"
        @input="onChange"
        disableDBlock
        size="md"
        customClass="category-modal"
    >
        <div role="tablist" class="tabbed-form">
            <b-card no-body class="mb-1 setup-category-card">
                <b-card-header header-tag="header" class="p-1 form-step-name" role="tab">
                    <img src="../../../assets/gcp-icons/step1.png" />
                    {{ tabs[0] }}
                </b-card-header>
                <b-collapse v-model="tabOpen[0]" visible role="tabpanel">
                    <b-card-body>
                        <b-form class="step-form">
                            <b-form-group label="Name" :label-cols="2" :horizontal="true">
                                <b-row>
                                    <b-col md="10">
                                        <b-form-input
                                            v-model="$v.categoryForm.name.$model"
                                            type="text"
                                            placeholder="Enter category name"
                                        ></b-form-input>
                                        <Feedback
                                            :state="validateRef('categoryForm.name', 0)"
                                            invalid="This field is required"
                                            valid="Name is valid"
                                        ></Feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>

                            <b-button @click="createCategory" variant="primary">
                                <i class="icon-check"></i>
                                Create
                            </b-button>
                        </b-form>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>
    </WitModal>
</template>

<script>
import Feedback from '@/components/Feedback.vue'
import WitModal from '@/components/Modals/WitModal.vue'

import {mapGetters} from 'vuex'
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
const cloneDeep = require('lodash.clonedeep')

export default {
    components: {
        Feedback,
        WitModal,
    },
    props: {
        value: {
            type: Object | undefined,
            required: true,
        },
        show: {
            type: Boolean,
            required: true,
        },
    },
    watch: {
        show() {
            this.showModal = this.show
            this.onChange(this.show)
        },
    },
    data() {
        return {
            categoryForm: {
                name: '',
            },
            tabs: ['Category Settings'],
            tabIndex: 0,
            checkPage: [],
            showModal: false,
        }
    },
    mixins: [validationMixin],
    validations: {
        categoryForm: {
            name: {required},
        },
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),
        tabOpen() {
            return this.tabs.map((tab, index) => (index === this.tabIndex ? true : false))
        },
    },
    created() {
        this.checkPage = this.tabs.map(el => false)
    },
    methods: {
        toggleStep(step) {
            this.tabIndex = step
            this.$forceUpdate()
        },

        createCategory() {
            this.checkPage[0] = true
            this.$forceUpdate()

            if (!this.$v.$invalid) {
                this.$store.commit('loading/PROCESSING', `Creating the Category...`)
                this.axios
                    .post(`${process.env.VUE_APP_NODE_API_HOST}/report/dataStudio/categories`, {
                        ...this.categoryForm,
                        projectId: this.activeProject.id,
                    })
                    .then(async response => {
                        this.$store.commit('loading/PROCESSED')
                        this.close()
                    })
                    .catch(exception => {
                        this.$store.commit('loading/PROCESSED')
                        this.$errorHandler.report(exception, 'Could not create data studio category')
                    })
            }
        },

        validateRef(ref, page) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)

            if (this.checkPage[page]) return !validateField.$invalid
            return validateField.$dirty ? !validateField.$invalid : null
        },

        onChange(show) {
            if (!show) {
                this.$emit('close')
            }
        },

        close() {
            this.showModal = false
            this.$emit('close')
        },
    },
}
</script>

<style lang="scss"></style>
